import image1 from "../../../assets/images/image1.png";
import gridImg from "../../../assets/images/grid-img.png";
import { createContext, useContext, useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar/Navbar";
import { SideBar } from "../../components/Sidebar/Sidebar";
import { useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { Functions, useEthereum } from "../../contexts/ethereum";
import { useAccount, useNetwork } from "wagmi";
import { FaSpinner } from "react-icons/fa";
import ether from "../../../assets/images/ether.svg";
import { ethers } from "ethers";
import { toast } from "react-toastify";

interface IMintContext {
  minting: boolean;
  setMinting: React.Dispatch<React.SetStateAction<boolean>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const MintContext = createContext({} as IMintContext);

const useMintContext = () => useContext(MintContext);

export const LandingPage = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [minting, setMinting] = useState(false);
  const [modal, setModal] = useState(false);

  return (
    <MintContext.Provider value={{ minting, setMinting, setModal }}>
      <Header setShowSidebar={setShowSidebar} />
      <Body />
      <Body2 />
      <Grid />
      <SideBar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      {modal && <MintModal />}
    </MintContext.Provider>
  );
};

const Header = ({
  setShowSidebar,
}: {
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div
      className={`w-full text-white relative flex justify-center py-48 px-10 flex-col items-center bg-cover bg-center bg-header-img md2:bg-header-img-sm md2:py-5`}
      // style={{ backgroundImage: `url(${headerImg})` }}
    >
      <Navbar setShowSidebar={setShowSidebar} />
      <div className="max-w-5xl flex flex-col justify-center items-center gap-14">
        <div className="font-medium">
          <p>All Access Pass Holders claim your FREE NFT (+gas&nbsp;only) </p>
          <p>Maryilyn Monroe Photography below. </p>
        </div>
        <MintButton />
      </div>
    </div>
  );
};

const Body = () => {
  return (
    <div className="w-full bg-fog text-black py-32 flex flex-col items-center gap-10 md2:py-5">
      <div className="max-w-5xl px-10">
        <h1 className="mb-6 font-bold">
          Free Marilyn Monroe NFT for All Access Pass Holders.
        </h1>
        <p className="text-lg md:text-justify">
          Collectors of our Phto. All Access Pass will be able to claim one FREE
          (Gas Only), Innovation of Influence, NFT for each All Access Pass
          owned. The distribution of the 500 NFT’s will be via blind mint with
          instant reveal. via a raffle system or randomized mint. With each All
          Access Pass acting as an entry into the allocation, the more All
          Access Passes a collector owns, the higher the probability of
          receiving a rare Marilyn Monroe NFT.
        </p>
      </div>
      <MintButton />
    </div>
  );
};

const Body2 = () => {
  return (
    <div className="w-full bg-black py-32 text-white flex justify-center md2:py-5">
      <div className="max-w-5xl w-full flex justify-center items-center gap-10 md2:flex-wrap px-10">
        <img src={image1} className="max-w-sm w-full" />
        <div className="self-stretch flex flex-col justify-center items-start gap-10 md2:items-center">
          <h1 className="text-start md2:text-center font-bold">
            Claim Your Free Marilyn Monroe NFT Photograph Below.
          </h1>
          <p className="text-lg text-start md2:text-center md:text-justify">
            Collectors of our Phto. All Access Pass will be able to claim, one
            FREE (Gas Only) Innovation of Influence NFT for each All Access Pass
            owned. The All Access Pass claimed NFT’s will be distributed
            randomly via free claim (+gas only) in a blind mint format. All
            Access Pass holders will have 24 hour advance minting privelages.
            With each All Access Pass acting as an entry into the allocation,
            the more All Access Passes a collector owns, the higher the
            probability of minting a one-of-one photograph.
          </p>
          <MintButton />
        </div>
      </div>
    </div>
  );
};

const Grid = () => {
  return (
    <div className="w-full text-black py-32 flex justify-center md2:py-5">
      <div className="max-w-5xl w-full flex justify-center items-center gap-10 md2:flex-wrap md2:flex-col-reverse md2:py-10 px-10">
        <div className="self-stretch flex flex-col justify-center items-start gap-10 md2:items-center">
          <p className="text-3xl text-start md2:text-center md2:text-2xl">
            The Norma Jeane collection by Richard C. Miller, as exhibited, sold
            and adored around the world, at major art institutions such as the
            Getty Museum and Christie’s Auction House.
          </p>
          <MintButton text="Claim Your Free Phto. NFT" />
        </div>
        <img src={gridImg} className="max-w-sm w-full" />
      </div>
    </div>
  );
};

const MintButton = ({ text }: { text?: string }) => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const {
    chainId,
    contractData,
    maxClaimableTokens,
    contractSigner,
    hexProof,
  } = useEthereum();

  const { setModal } = useMintContext();

  const { openConnectModal } = useConnectModal();
  const { openChainModal } = useChainModal();

  const { minting, setMinting } = useMintContext();

  const inChain = chainId === chain?.id;

  const state = contractData?.[Functions.saleState];
  const claimState = state === 1 && !!maxClaimableTokens;
  const claimed = contractData?.[Functions.claimed]?.toString();

  const disabled =
    minting ||
    state === 0 ||
    (claimState && !(maxClaimableTokens - contractData?.[Functions.claimed]));

  useEffect(() => {
    if (!minting) return;
    setMinting(false);
    toast.success("Congrats, you claimed a Phto.");
  }, [contractData?.[Functions.totalBalance]]);

  const mint = async () => {
    if (chain?.id != chainId) {
      return openChainModal && openChainModal();
    }
    setMinting(true);
    try {
      if (state === 1) {
        const canClaim = maxClaimableTokens - contractData?.[Functions.claimed];
        const claim = Math.min(
          canClaim,
          contractData[Functions.maxPerTransaction]
        );
        await contractSigner?.claim(claim, maxClaimableTokens, hexProof);
      }
      if (state === 2) {
        setModal(true);
      }
      setMinting(false);
    } catch (e: any) {
      setMinting(false);
      toast.error(e?.reason);
      console.log(e);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-2 font-semibold text-base md:text-xs text-white select-none">
      {claimState && (
        <div className="bg-fire p-2 rounded">
          Claimed: {claimed}/{maxClaimableTokens}
        </div>
      )}
      <button
        className="bg-fire  px-8 py-4 rounded-tl-lg rounded-br-lg disabled:bg-fire-dark disabled:cursor-not-allowed"
        onClick={address ? (inChain ? mint : openChainModal) : openConnectModal}
        disabled={disabled}
      >
        {minting ? (
          <>
            <FaSpinner className="icon-spin inline-flex" /> Minting
          </>
        ) : state === 2 ? (
          "Purchase Your Marilyn Monroe NFT"
        ) : (
          text || "Claim Your Free Marilyn Monroe NFT"
        )}
      </button>
      {claimState &&
        maxClaimableTokens - contractData?.[Functions.claimed] > 15 && (
          <div className="bg-fire p-2 rounded">
            (Claim up to 15 tokens per transaction)
          </div>
        )}
    </div>
  );
};

const MintModal = () => {
  const { setModal } = useMintContext();
  const { contractData, contractSigner, chainId } = useEthereum();
  const { chain } = useNetwork();
  const { openChainModal } = useChainModal();

  const [minting, setMinting] = useState(false);
  const [amount, setAmount] = useState(1);

  const price = contractData?.[Functions.price];

  const mint = async () => {
    if (chain?.id != chainId) {
      return openChainModal && openChainModal();
    }
    setMinting(true);
    try {
      const receipt = await contractSigner?.purchase(amount, {
        value: price.mul(amount),
      });
      await receipt.wait();
      toast.success("Congrats, you mintied a Phto.");
      setMinting(false);
    } catch (e: any) {
      toast.error(e?.reason);
      console.log({ e });
      setMinting(false);
    }
  };

  return (
    <div
      className="fixed bg-modal inset-0 flex justify-center items-center"
      onClick={() => !minting && setModal(false)}
    >
      <div
        className="p-10 text-fire bg-fog rounded font-normal"
        onClick={(e) => e.stopPropagation()}
      >
        <p>Amount: {amount}</p>
        <input
          type={"range"}
          className="bg-transparent w-52"
          value={amount}
          min={1}
          max={15}
          onChange={(e) => setAmount(Number(e.target.value))}
        />
        {price && (
          <p className="flex justify-center items-center">
            Price: {ethers.utils.formatEther(price.mul(amount))}{" "}
            <img src={ether} className="inline pb-2" />
          </p>
        )}
        <button
          className="bg-fire text-white p-2 px-5 rounded disabled:cursor-not-allowed"
          onClick={mint}
          disabled={minting}
        >
          {minting ? (
            <>
              <FaSpinner className="icon-spin inline-flex" /> Minting
            </>
          ) : (
            "Mint"
          )}
        </button>
      </div>
    </div>
  );
};
