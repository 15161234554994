import COAimg from "../../../assets/deleteLater/COA.jpg";
import { useState } from "react";
import { Navbar } from "../../components/Navbar/Navbar";
import { SideBar } from "../../components/Sidebar/Sidebar";
import { useAccount } from "wagmi";

export const COA = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      <Header setShowSidebar={setShowSidebar} />
      <Body />
      <Body2 />
      <SideBar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
    </>
  );
};

const Header = ({
  setShowSidebar,
}: {
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div
      className={`w-full text-white relative flex justify-center py-64 px-10 flex-col items-center bg-cover bg-center bg-header-img md2:bg-header-img-sm md2:py-5`}
      // style={{ backgroundImage: `url(${headerImg})` }}
    >
      <Navbar setShowSidebar={setShowSidebar} />
      <div className="max-w-5xl flex flex-col justify-center items-center gap-14 md2:pb-20">
        <div>
          <p className="font-medium text-4xl">
            Supporting Documents<span className="text-fire">.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const Body = () => {
  return (
    <div className="w-full bg-white text-black py-24 flex flex-col items-center gap-10 md2:py-5">
      <div className="max-w-5xl px-10">
        <h1 className="mb-6 font-semibold">
          Supporting Documents<span className="text-fire">.</span> Phto
          <span className="text-fire">.</span> Certificate of Authenticity
          Initiative<span className="text-fire">.</span>{" "}
        </h1>
        <p className="text-xl font-medium">
          In effort to ensure each collaboration is propertly memorialized,
          supporting documentation is signed by all participating parties. A
          sworn Affidavit is singned and notarized prior to the release of any
          collections. The supporting documentation will also be made available
          to the community.
        </p>
      </div>
    </div>
  );
};

const Body2 = () => {
  const { address } = useAccount();

  if (!address) return <NotConnected />;

  return (
    <div className="w-full bg-fog py-32 text-black flex justify-center md2:py-5">
      <div className="max-w-5xl w-full flex justify-center items-center gap-10 md2:flex-wrap px-10">
        <img src={COAimg} className="max-w-sm w-full" />
        <div className="self-stretch flex flex-col justify-center items-start gap-10 md2:items-center">
          <h1 className="text-start md2:text-center font-bold">
            Certificate of Authenticity<span className="text-fire">.</span>
          </h1>
          <p className="text-lg font-medium text-start md2:text-center ">
            In effort to ensure each collaboration is properly memorialized,
            supporting documentation is signed by all participating parties. A
            sworn Affidavit is signed and notarized prior to the release of any
            collections. This supporting documentation will also be made
            available to the community.
          </p>
          <a
            className="bg-fire font-semibold px-8 py-4 rounded-tl-lg rounded-br-lg text-base text-white md:text-xs select-none"
            href={COAimg}
            download="phtoCOA.jpg"
          >
            View the Collection
          </a>
        </div>
      </div>
    </div>
  );
};

const NotConnected = () => {
  return (
    <div className="w-full bg-fire py-32 text-white flex justify-center md2:py-5">
      <div className="max-w-5xl w-full flex justify-center items-center gap-10 md2:flex-wrap px-10 text-5xl">
        <p className="font-bold text-5xl md2:text-2xl">
          Portfolio Members Only
        </p>
      </div>
    </div>
  );
};
