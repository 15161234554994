import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import "@rainbow-me/rainbowkit/styles.css";
import { ToastContainer } from "react-toastify";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import {
  AvatarComponent,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { EthereumProvider } from "./common/contexts/ethereum";
import * as blockies from "blockies-ts";
import { AvatarComponentProps } from "@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/AvatarContext";

const { chains, provider } = configureChains(
  [
    chain.mainnet,
    // chain.goerli,
    // chain.localhost,
  ],
  [
    jsonRpcProvider({
      rpc: (chain) =>
        chain.id === 1337
          ? { http: "http://localhost:8545" }
          : chain.id === 1
          ? {
              http: "https://cold-wispy-firefly.quiknode.pro/efe16c65e7bdd497c2a34cdef175db8385bed823/",
            }
          : {
              http: "https://alien-long-silence.ethereum-goerli.quiknode.pro/a798f49722b8ac00cbc9aabdca5e7c35f39d3ff0/",
              webSocket:
                "wss://alien-long-silence.ethereum-goerli.quiknode.pro/a798f49722b8ac00cbc9aabdca5e7c35f39d3ff0/",
            },
    }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Aquarium Treasury Dapp",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const CustomAvatar: AvatarComponent = ({
  address,
  ensImage,
  size,
}: AvatarComponentProps) => {
  return ensImage ? (
    <img
      src={ensImage}
      width={size}
      height={size}
      style={{ borderRadius: 999 }}
    />
  ) : (
    <img
      src={blockies.create({ seed: address.toLowerCase() }).toDataURL()}
      width={size}
      height={size}
    />
  );
};

ReactDOM.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        coolMode
        avatar={CustomAvatar}
        // showRecentTransactions={true}
      >
        <EthereumProvider dev={"mainnet"}>
          <App />
          <ToastContainer position="bottom-right" />
        </EthereumProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
