import "./footer.css";
import { ReactComponent as AtlasLogo } from "../../../assets/images/atlasIcon.svg";
import twitter from "../../../assets/images/twitter.svg";
import instagram from "../../../assets/images/instagram.svg";

const Footer = (): JSX.Element => {
  const loc = window.location.pathname;

  return (
    <>
      <nav className="py-10 footer min-h-100 text-white flex-wrap items-center flex-col bg-cover gap-3 p-4 bg-footer-img md2:bg-footer-img-sm select-none">
        <ul className="text-left pl-5 text-xl flex gap-5 flex-wrap md2:hidden">
          <li className="underline">
            <a
              href="/"
              rel="noreferrer"
              className={`${loc === "/" ? "underline" : ""}`}
            >
              Phto.
            </a>
          </li>
          <li>
            <a
              href="https://www.phto.io/all-access-pass"
              rel="noreferrer"
              target={"_blank"}
            >
              All Access Pass
            </a>
          </li>
          <li>
            <a
              href="/coa"
              rel="noreferrer"
              className={`${loc === "/coa" ? "underline" : ""}`}
            >
              C.O.A
            </a>
          </li>
          <li>
            <a
              href="https://www.phto.io/terms-conditions"
              target={"_blank"}
              rel="noreferrer"
            >
              Terms.
            </a>
          </li>
          <li>
            <a
              href="https://www.phto.io/faqs"
              target={"_blank"}
              rel="noreferrer"
            >
              FAQ&apos;s
            </a>
          </li>
          <li>
            <a
              href="https://www.phto.io/contactphto"
              target={"_blank"}
              rel="noreferrer"
            >
              Contact
            </a>
          </li>
        </ul>
        <p className="text-base">Located in The Otherside. </p>
        <p className="text-sm">Collect@Phto.io</p>
        <div className="">
          <div className="flex gap-2">
            <a
              href="https://twitter.com/phtoNFT"
              target={"_blank"}
              rel="noreferrer"
              className="flex"
            >
              <div className="bg-white p-2 flex justify-center rounded-full">
                <img src={twitter} />
              </div>
            </a>
            <a
              href="http://instagram.com/phtoNFT"
              target={"_blank"}
              rel="noreferrer"
              className="flex"
            >
              <div className="bg-white p-2 flex justify-center rounded-full">
                <img src={instagram} />
              </div>
            </a>
          </div>
        </div>
        <a
          className="logo-container lg:w-full flex text flex-wrap justify-center items-center gap-2"
          href="https://www.atlascorp.io/"
          target={"_blank"}
          rel="noreferrer"
        >
          <div className="text-2xl">Developed by</div>
          <AtlasLogo className="atlas-icon h-10 flex flex-wrap justify-center max-w-fit fill-white" />
          {/* <Name className="atlas-name" /> */}
        </a>
      </nav>
    </>
  );
};

export default Footer;
