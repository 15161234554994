import navImg from "../../../assets/images/nav-img.png";
import hamburger from "../../../assets/images/hamburger.svg";
import { useAccount, useNetwork } from "wagmi";
import { addressConcat } from "../../../utils/address_utils";
import {
  useAccountModal,
  useChainModal,
  useConnectModal,
} from "@rainbow-me/rainbowkit";
import { useEthereum } from "../../contexts/ethereum";

export const Navbar = ({
  setShowSidebar,
}: {
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { chainId } = useEthereum();

  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();

  const inChain = chainId === chain?.id;

  const loc = window.location.pathname;

  return (
    <nav className="absolute top-0 p-12 justify-between items-center select-none md2:static md2:p-0">
      <ul className="text-sm flex gap-3 lg:hidden font-bold">
        <li>
          <a
            href="/"
            rel="noreferrer"
            className={`${loc === "/" ? "underline" : ""}`}
          >
            Phto.
          </a>
        </li>
        <li>
          <a
            href="/coa"
            rel="noreferrer"
            className={`${loc === "/coa" ? "underline" : ""}`}
          >
            C.O.A
          </a>
        </li>
        <li>
          <a
            href="https://www.phto.io/terms-conditions"
            target={"_blank"}
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
        </li>
        <li>
          <a href="https://www.phto.io/faqs" target={"_blank"} rel="noreferrer">
            FAQ&apos;s
          </a>
        </li>
        <li>
          <a
            href="https://www.phto.io/contactphto"
            target={"_blank"}
            rel="noreferrer"
          >
            Contact
          </a>
        </li>
      </ul>
      <img
        src={navImg}
        className="absolute right-0 left-0 h-20 mx-auto lg:static lg:m-0 lg:h-14 md2:h-10"
      />
      <div className="flex gap-2">
        <button
          className={
            "bg-fire font-bold px-5 py-2 rounded-tl-lg rounded-br-lg text-base md:text-xs"
          }
          onClick={
            address
              ? inChain
                ? openAccountModal
                : openChainModal
              : openConnectModal
          }
        >
          {address
            ? inChain
              ? addressConcat(address, 6)
              : "Switch Chain"
            : "Connect Wallet"}
        </button>
        <div
          className="self-stretch bg-fire-dark hidden lg:flex h-10 p-3 rounded cursor-pointer"
          onClick={() => setShowSidebar(true)}
        >
          <img src={hamburger} />
        </div>
      </div>
    </nav>
  );
};
