import logo from "../../../assets/images/nav-img.png";

export const SideBar = ({
  showSidebar,
  setShowSidebar,
}: {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  if (!showSidebar) return null;

  const loc = window.location.pathname;

  return (
    <div
      className="text-white fixed left-0 right-0 z-auto bg-modal w-full h-full select-none"
      onClick={() => setShowSidebar(false)}
    >
      <aside className="h-full bg-[#202020] w-64 ml-auto">
        <img src={logo} className="h-20 mx-auto" />
        <ul className="text-left pl-5 text-xl flex flex-col gap-2">
          <li className="underline">
            <a
              href="/"
              rel="noreferrer"
              className={`${loc === "/" ? "underline" : ""}`}
            >
              Phto.
            </a>
          </li>
          <li>
            <a
              href="/coa"
              rel="noreferrer"
              className={`${loc === "/coa" ? "underline" : ""}`}
            >
              C.O.A
            </a>
          </li>
          <li>
            <a
              href="https://www.phto.io/terms-conditions"
              target={"_blank"}
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </li>
          <li>
            <a
              href="https://www.phto.io/faqs"
              target={"_blank"}
              rel="noreferrer"
            >
              FAQ&apos;s
            </a>
          </li>
          <li>
            <a
              href="https://www.phto.io/contactphto"
              target={"_blank"}
              rel="noreferrer"
            >
              Contact
            </a>
          </li>
        </ul>
      </aside>
    </div>
  );
};
