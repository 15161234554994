import { Contract, ethers } from "ethers";
import MerkleTree from "merkletreejs";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useAccount, useContractReads, useSigner } from "wagmi";
import { addressConcat } from "../../utils/address_utils";
import abi from "../constants/abi.json";
import whitelist from "../constants/whitelist.json";

export interface IEthereumContextValues {
  contractData: any;
  contractSigner?: Contract;
  chainId: number;
  maxClaimableTokens: number;
  hexProof: string[];
}

export enum Functions {
  saleState,
  price,
  supply,
  maxSupply,
  maxPerTransaction,
  claimed,
  balancesOfUser,
  totalBalance,
}

export const EthereumContext = createContext({} as IEthereumContextValues);

export const useEthereum = () => {
  return useContext(EthereumContext);
};

export const EthereumProvider = ({
  children,
  dev,
}: {
  children: React.ReactNode;
  dev?: "dev" | "goerli" | "mainnet";
}): JSX.Element => {
  const { ethereum } = window;
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const [hexProof, setHexProof] = useState<string[]>([]);

  const chainId: number = dev === "dev" ? 1337 : dev === "mainnet" ? 1 : 5;

  const goerliAddress = "0x94f446794916FAC598aC45eBB0ce0CE4FBF2C202";
  const mainnnetAddress = "0x36D9987dFB6008532BDe0F7B297aB201B131dD27";
  const devAddress = "";

  const contract = {
    addressOrName:
      dev == "dev"
        ? devAddress
        : dev === "goerli"
        ? goerliAddress
        : mainnnetAddress,
    contractInterface: abi,
  };

  const contractDataRead = useContractReads({
    contracts: [
      { ...contract, functionName: "saleState", chainId },
      { ...contract, functionName: "PRICE", chainId },
      { ...contract, functionName: "counter", chainId },
      { ...contract, functionName: "MAX_TOKENS_IN_SALE", chainId },
      { ...contract, functionName: "MAX_PER_TRANSACTION", chainId },
      {
        ...contract,
        functionName: "tokensClaimedByAddress",
        chainId,
        args: [address],
      },
      {
        ...contract,
        functionName: "balanceOfBatch",
        args: [
          new Array(102).fill(address),
          [...Array(102)].map((_, index) => index + 1),
        ],
        chainId,
      },
      {
        ...contract,
        functionName: "getTotalBalance",
        args: [address],
        chainId,
      },
    ],
    watch: true,
  });

  const contractData = contractDataRead.data;

  const contractSigner = useMemo(() => {
    if (signer) {
      return new Contract(
        dev === "dev"
          ? devAddress
          : dev === "goerli"
          ? goerliAddress
          : mainnnetAddress,
        abi,
        signer
      );
    }
  }, [signer]);

  const maxClaimableTokens = Number(
    whitelist.find(
      (x) => (x[0] as string).toLowerCase() === address?.toLowerCase()
    )?.[1] || 0
  );

  useEffect(() => {
    if (ethereum) {
      ethereum.on("accountsChanged", (accounts: any) => {
        if (accounts) {
          console.log("changed acccount", accounts[0]);
          toast(`Changed Account: \n ${addressConcat(accounts[0])}`, {
            style: { color: "#7F3B2F" },
          });
        }
      });
    }
    return () => {
      ethereum.removeListener("accountsChanged");
    };
  }, []);

  useEffect(() => {
    if (address) {
      const leaves = whitelist.map((x) =>
        ethers.utils.solidityKeccak256(["address", "uint256"], [x[0], x[1]])
      );
      const tree = new MerkleTree(leaves, ethers.utils.keccak256, {
        sortPairs: true,
      });
      if (!tree) return;

      const user = whitelist.find((x) => x[0] === address);

      if (!user) return;

      const hexProof = tree.getHexProof(
        ethers.utils.solidityKeccak256(
          ["address", "uint256"],
          [user[0], user[1]]
        )
      );
      setHexProof(hexProof);
      // console.log(hexProof);
      // console.log("root:", tree.getHexRoot());
    }
  }, [address]);

  return (
    <EthereumContext.Provider
      value={{
        contractData,
        contractSigner,
        maxClaimableTokens,
        chainId,
        hexProof,
      }}
    >
      {children}
    </EthereumContext.Provider>
  );
};
